import { SIDE_NAV_LIGHT, NAV_TYPE_TOP, DIR_LTR } from "constants/ThemeConstant";

export const APP_NAME = "adindexparking";
export const API_BASE_URL = "localhost";
export const APP_PREFIX_PATH = "/app";

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: "en",
	navType: NAV_TYPE_TOP,
	topNavColor: "#009fe3",
	headerNavColor: "",
	mobileNav: false,
	currentTheme: "light",
	direction: DIR_LTR,
};
