import React from "react";
import { Switch, Route } from "react-router-dom";
import AppViews from "views/app-views";

export const AppLayout = () => {
	return (
		<div className="auth-container">
			<Switch>
				<Route path="" component={AppViews} />
			</Switch>
		</div>
	);
};

export default AppLayout;
