import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
	Card,
	Row,
	Col,
	message,
	Table,
	Button,
	Drawer,
	Radio,
	Space,
} from "antd";
import { useSelector } from "react-redux";
import adindexService from "services/AdindexService";
import axios from "axios";
import {
	showLoading,
	showAuthMessage,
	hideAuthMessage,
	authenticated,
} from "redux/actions/Auth";
import AvatarStatus from "components/shared-components/AvatarStatus";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const backgroundStyle = {
	backgroundImage: "url(/img/others/img-17.jpg)",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
};

const LoginOne = (props) => {
	const theme = useSelector((state) => state.theme.currentTheme);

	const [loading, setLoading] = useState(true);
	const [domain, setDomain] = useState("domain.tld");
	const [title, setTitle] = useState("Welcome to %DOMAIN_NAME%");
	const [keywords, setKeywords] = useState(null);
	const [region, setRegion] = useState(null);
	const [adspace, setAdspace] = useState(null);
	const [customId, setCustomId] = useState("customId");
	const [email, setEmail] = useState(null);

	const [productList, setProductList] = useState([]);
	const [visibleProducts, setVisibleProducts] = useState([]);
	const [pageSize, setPageSize] = useState(10);

	const columns = [
		{
			title: "",
			dataIndex: "productImages",
			render: (_, record) => (
				<div className="d-flex">
					<AvatarStatus size={60} type="square" src={record.image} />
				</div>
			),
		},
		{
			title: "",
			dataIndex: "title",
			render: (_, record) => <a href={record.clickUrl}>{record.title}</a>,
		},
	];

	useEffect(() => {
		setLoading(true);
		adindexService
			.getProducts(
				JSON.stringify({
					itemsPerPage: pageSize,
					search: keywords,
					country: region,
					adspaceId: adspace,
					customId: domain,
				})
			)
			.then((resp) => {
				//setProductList([ { image: "", title: "test", clickUrl: "testclick" }, { image: "", title: "test", clickUrl: "testclick" }, { image: "", title: "test", clickUrl: "testclick" }, { image: "", title: "test", clickUrl: "testclick" }, { image: "", title: "test", clickUrl: "testclick" }, { image: "", title: "test", clickUrl: "testclick" }, { image: "", title: "test", clickUrl: "testclick" }, { image: "", title: "test", clickUrl: "testclick" }, { image: "", title: "test", clickUrl: "testclick" }, { image: "", title: "test", clickUrl: "testclick" }, ]);
				//return;
				if (resp.data.error) {
					message.error(resp.data.error.message);
				} else {
					setProductList(resp.data.items.slice(0, 10));
				}
			})
			.finally(() => setLoading(false));
	}, [domain, region, adspace, customId]);

	const calculatePageSize = () => {
		const headerHeight = 250; // Geschätzte Höhe des Tabellen-Headers
		const rowHeight = 93; // Geschätzte Höhe pro Tabellenzeile
		const cardPadding = 0; // Innenabstände der Card-Komponente
		const viewportHeight = window.innerHeight;

		const availableHeight = viewportHeight - headerHeight - cardPadding;
		const calculatedPageSize = Math.floor(availableHeight / rowHeight);

		setPageSize(calculatedPageSize > 0 ? calculatedPageSize : 1);
		console.log(calculatedPageSize > 0 ? calculatedPageSize : 1);
	};

	// Listener für Fenstergröße
	useEffect(() => {
		calculatePageSize(); // Initiale Berechnung

		window.addEventListener("resize", calculatePageSize);
		return () => {
			window.removeEventListener("resize", calculatePageSize);
		};
	}, []);

	useEffect(() => {
		setVisibleProducts(productList.slice(0, pageSize));
	}, [pageSize, productList]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get("/api/data");
				setDomain(response.data.domain);
				setTitle(response.data.title);
				setKeywords(response.data.keywords);
				setRegion(response.data.region);
				setAdspace(response.data.adspace);
				setCustomId(response.data.customId);
				setProductList(response.data.products);
				setEmail(response.data.email);
			} catch (err) {
				message.error(err.message);
			}
		};

		fetchData();
	}, []);

	const [open, setOpen] = useState(false);
	const [placement, setPlacement] = useState("left");
	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};
	const onChange = (e) => {
		setPlacement(e.target.value);
	};
	return (
		<div className="h-100" style={backgroundStyle}>
			<div className="container d-flex flex-column justify-content-center h-100">
				<Row justify="center">
					<Col xs={20} sm={20} md={20} lg={20}>
						<Card
							title={title.replaceAll("%DOMAIN_NAME%", domain)}
							style={{
								height: "95vh",
							}}
							headStyle={{
								fontSize: 24,
							}}
						>
							<div className="my-4">
								<Row justify="center">
									<Col xs={24} sm={24} md={20} lg={20}>
										<Table
											title={() => "Related Links"}
											columns={columns}
											// rowKey={(record) => record.id}
											dataSource={visibleProducts}
											loading={loading}
											pagination={false}
										/>
									</Col>
								</Row>
							</div>
						</Card>
					</Col>
				</Row>

				<Row justify="center">
					<Col xs={20} sm={20} md={20} lg={20}>
						<div className="text-center text-white">
							<Button
								type="link"
								style={{ color: "#fff" }}
								href="https://www.adindex.com/domainparking"
								target="_blank"
							>
								Adindex Domain Parking
							</Button>
							<Button
								type="link"
								style={{ color: "#fff" }}
								href="https://www.adindex.com/privacy-policy"
								target="_blank"
							>
								Privacy Policy
							</Button>
							{email && (
								<Button
									type="link"
									style={{ color: "#fff" }}
									onClick={() => (window.location = "mailto:" + email)}
								>
									Contact
								</Button>
							)}
						</div>
					</Col>
				</Row>

				<div
					className="text-right mt-2"
					style={{ position: "fixed", right: 20, bottom: 20 }}
				>
					<div className="text-white mb-2">powered by</div>
					<a href="https://www.adindex.com" target="_blank">
						<img src="/img/logo-white.png" alt="logo" />
					</a>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = ({ auth }) => {
	const { loading, message, showMessage, token, redirect } = auth;
	return { loading, message, showMessage, token, redirect };
};

const mapDispatchToProps = {
	showAuthMessage,
	showLoading,
	hideAuthMessage,
	authenticated,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginOne);
