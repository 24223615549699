import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AuthLayout from "layouts/app-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import useBodyClass from "hooks/useBodyClass";

export const Views = (props) => {
	const { locale, token, location, direction } = props;
	const currentAppLocale = AppLocale[locale];
	useBodyClass(`dir-${direction}`);
	return (
		<IntlProvider
			locale={currentAppLocale.locale}
			messages={currentAppLocale.messages}
		>
			<ConfigProvider locale={currentAppLocale.antd} direction={direction}>
				<Switch>
					<Route exact path="/">
						<AuthLayout direction={direction} />
					</Route>
				</Switch>
			</ConfigProvider>
		</IntlProvider>
	);
};

const mapStateToProps = ({ theme, auth }) => {
	const { locale, direction } = theme;
	const { token } = auth;
	return { locale, direction, token };
};

export default withRouter(connect(mapStateToProps)(Views));
